<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_hospitalization") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <template
                v-if="
                  (bunk.id ? bunk.id : '') &&
                  (bunk.id ? bunk.id : '') !=
                    (form.patientSchedule ? form.patientSchedule.id : '')
                "
              >
                <el-button disabled>{{ $t("message.close") }}</el-button>
              </template>
              <template v-else>
                <el-button @click="close()">{{
                  $t("message.close")
                }}</el-button>
              </template>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form" v-loading="loadingData">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item :label="$t('message.number_card')">
              <el-input v-model="form.number" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <el-form-item
              :label="$t('message.date_hospitalization')"
              prop="date_gos"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_gos"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <el-form-item
              :label="$t('message.diagnos')"
              prop="diagnos"
              class="ik_label"
            >
              <el-input v-model="form.diagnos" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <el-form-item :label="$t('message.doctor')" prop="doctor_id">
              <users
                v-model="form.doctor_id"
                :doctor_id="form.doctor_id"
              ></users>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <el-form-item :label="$t('message.branch')" prop="branch_id">
              <branches
                v-model="form.branch_id"
                :branch_id="form.branch_id"
              ></branches>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <el-form-item
              :label="$t('message.hospitalization_type')"
              prop="type_hospitalization_id"
            >
              <typeHospitalizations
                v-model="form.type_hospitalization_id"
                :type_hospitalization_id="form.type_hospitalization_id"
              ></typeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              :label="$t('message.purpose_of_hospitalization')"
              prop="purpose_hospitalization_id"
            >
              <purposeHospitalizations
                v-model="form.purpose_hospitalization_id"
                :purpose_hospitalization_id="form.purpose_hospitalization_id"
              ></purposeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item :label="$t('message.blood')">
              <el-select v-model="form.blood" placeholder=".....">
                <el-option label="O(I) Rh−" value="first_minus"></el-option>
                <el-option label="O(I) Rh+" value="first_plus"></el-option>
                <el-option label="A(II) Rh−" value="second_minus"></el-option>
                <el-option label="A(II) Rh+" value="second_plus"></el-option>
                <el-option label="B(III) Rh−" value="third_minus"></el-option>
                <el-option label="B(III) Rh−" value="third_plus"></el-option>
                <el-option label="AB(IV) Rh−" value="fourth_minus"></el-option>
                <el-option label="AB(IV) Rh+" value="fourth_plus"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.puls')" class="ik_label">
              <el-row>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_one"
                    placeholder="......"
                  ></el-input>
                </el-col>
                <el-col class="line text-center" :span="2">-</el-col>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_two"
                    placeholder="......"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.temperature')">
              <el-input
                v-model="form.teperatura"
                placeholder="......"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.condition')" prop="status_id">
              <injuryStatuses
                v-model="form.status_id"
                :status_id="form.status_id"
              ></injuryStatuses>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.weight')">
              <el-input v-model="form.weight" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.height')">
              <el-input v-model="form.height" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item
              :label="$t('message.type_of_injury')"
              prop="injury_type_id"
            >
              <injuryTypes
                v-model="form.injury_type_id"
                :injury_type_id="form.injury_type_id"
              ></injuryTypes>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.allergy')">
              <el-input type="textarea" v-model="form.allergy"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.date_injury')" prop="date_injury">
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_injury"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item
              :label="$t('message.date_register')"
              prop="date_register"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_register"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-tabs type="border-card" class="mt-2">
              <el-tab-pane :label="$t('message.direction')">
                <el-row :gutter="25">
                  <el-col :span="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <el-select
                          v-model="form.partner_clinic_id"
                          filterable
                          clearable
                          size="mini"
                          @change="filterDepartment"
                          :placeholder="$t('message.partner_clinic')"
                        >
                          <el-option
                            v-for="item in partnerClinics"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <!-- <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      > -->
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <el-select
                          size="mini"
                          v-model="form.department_id"
                          filterable
                          clearable
                          :placeholder="$t('message.department')"
                        >
                          <el-option
                            v-for="item in departments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerDepartment = true"
                        >{{ $t("message.add_new_department") }}</el-button
                      >
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <partnerDoctor
                          v-model="form.partner_doctor_id"
                          :partner_clinic_id="form.partner_clinic_id"
                          :id="model.partner_doctor_id"
                          :partner_doctor_id="form.partner_doctor_id"
                          size="mini"
                        ></partnerDoctor>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      >
                    </div>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end el-row -->
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.add_to_room')">
                <el-row :gutter="20">
                  <el-col :span="8" v-if="classs">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.class") }}:</div>
                      <div class="name_h" v-text="classs"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.class") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.classs.name
                            : classs
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="corps">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.corps") }}:</div>
                      <div class="name_h" v-text="corps">Корпус №1</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.corps") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.corps.name
                            : corps
                        "
                      >
                        Корпус №1
                      </div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="floor">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.floor") }}:</div>
                      <div class="name_h" v-text="floor"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.floor") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.floor.name
                            : floor
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="room">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.room") }}:</div>
                      <div class="name_h" v-text="room"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.room") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.hospitalRoom.name
                            : room
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="bunkname">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.bunk") }}:</div>
                      <div class="name_h" v-text="bunkname"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.bunk") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.name
                            : bunkname
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="start">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.arrival_date") }}:
                      </div>
                      <div class="name_h" v-text="start"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.arrival_date") }}:
                      </div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.start
                            : start
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8" v-if="end">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.departure_date") }}:
                      </div>
                      <div class="name_h" v-text="end"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.departure_date") }}:
                      </div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule ? form.patientSchedule.end : end
                        "
                      ></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                </el-row>

                <el-col :span="24">
                  <el-button
                    icon="el-icon-edit"
                    @click="drawer.room.status = true"
                    type="primary"
                    round
                    class="mb-1 mb-2"
                    >{{ $t("message.update") }}</el-button
                  >
                </el-col>
              </el-tab-pane>
              <!-- end  el-tab-pane-->
            </el-tabs>
          </el-col>
        </el-row>

        <!-- end el-row -->
      </el-form>
    </div>
    <el-drawer
      title="I am the title"
      :append-to-body="true"
      :visible.sync="drawer.room.status"
      size="100%"
      :ref="drawer.room.name"
      :with-header="false"
      @opened="drawerOpened(drawer.room.component)"
      @closed="drawerClosed(drawer.room.component)"
    >
      <ModalUpdate
        :ref="drawer.room.component"
        :drawer-name="drawer.room.name"
      />
    </el-drawer>

    <el-drawer :append-to-body="true" :visible.sync="drawerPartner" size="40%">
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalUpdate from "./modalUpdate";
import form from "@/utils/mixins/form";
import branches from "@/components/selects/branchWarehouse";
import users from "@/components/selects/user_list";
import drawerChild from "@/utils/mixins/drawer-child";
import injuryTypes from "@/components/selects/injuryType";
import injuryStatuses from "@/components/selects/injuryStatus";
import typeHospitalizations from "@/components/selects/typeHospitalization";
import purposeHospitalizations from "@/components/selects/purposeHospitalization";

import partnerDoctor from "@/components/inventory-select/partner-doctor";
import CreateDepartment from "@/views/scheduler/add-department";
import AddPartnerDoctor from "@/views/scheduler/add-partner-doctor";
import drawer from "@/utils/mixins/drawer";

export default {
  props: ["selected"],
  mixins: [form, drawerChild, drawer],
  components: {
    branches,
    CreateDepartment,
    injuryTypes,
    purposeHospitalizations,
    users,
    typeHospitalizations,
    injuryStatuses,
    ModalUpdate,
    partnerDoctor,
    AddPartnerDoctor,
  },
  data() {
    return {
      loadingData: false,
      form: {},
      corps: "",
      floor: "",
      classs: "",
      room: "",
      drawerDepartment: false,
      bunkname: "",
      loadingData: false,
      start: "",
      end: "",
      drawerPartner: false,
      drawer: {
        room: {
          status: false,
          name: "room",
          component: "roomComponent",
        },
      },
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      rules: "patientHistories/rules",
      model: "patientHistories/model",
      columns: "patientHistories/columns",
      selected_partner_doctor: "partnerDoctor/selected_partner_doctor",
      bunk: "schedulesCabinets/bunk",
      departments: "department/list",
      partnerClinics: "partnerClinic/list",
      partnerDoctors: "partnerDoctor/list",
      lastHistory: "dailyTreatments/lastHistory",
    }),
  },

  methods: {
    ...mapActions({
      save: "patientHistories/update",
      empty: "patientHistories/empty",
      editModel: "patientHistories/show",
      loadDepartments: "department/index",
      partnerClinicsList: "partnerClinic/index",
      partnerDoctorsList: "partnerDoctor/inventory",
    }),
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    afterOpened() {
      this.getData();
      var order_query = { column: "name", order: "asc" };

      if (this.partnerDoctors && this.partnerDoctors.length === 0)
        this.partnerDoctorsList(order_query);

      if (this.departments && this.departments.length === 0)
        this.loadDepartments(order_query);

      if (this.partnerClinics && this.partnerClinics.length === 0)
        this.partnerClinicsList(order_query);
    },
    afterClosed() {
      this.empty();
    },
    getData() {
      this.loadingData = true;
      var vm = this;
      this.editModel({
        hospital_patient_id: vm.getId,
        id: this.lastHistory.id,
      })
        .then((res) => {
          this.form = JSON.parse(JSON.stringify(this.model));
          this.form.hospital_patient_id = this.selected.hospitalPatient.id;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$alert(err);
        });
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      if (this.bunk ? this.bunk.id : "") {
        this.form.patient_schedule_id = this.bunk ? this.bunk.id : "";
      } else {
        this.form.patient_schedule_id = this.form.patientSchedule
          ? this.form.patientSchedule.id
          : "";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    loadBunk() {
      this.classs = this.bunk.bunk ? this.bunk.bunk.classs.name : "";
      this.corps = this.bunk.bunk ? this.bunk.bunk.corps.name : "";
      this.floor = this.bunk.bunk ? this.bunk.bunk.floor.name : "";
      this.room = this.bunk.bunk ? this.bunk.bunk.hospitalRoom.name : "";
      this.bunkname = this.bunk.bunk ? this.bunk.bunk.name : "";
      this.start = this.bunk.start;
      this.end = this.bunk.end;
      this.form.patient_schedule_id = this.bunk.id;
    },
    async closeDrawerAddPartnerDoctor(obj) {
      if (obj.reload) {
        await this.partnerDoctorsList({ column: "name", order: "asc" }).then(
          (res) => {
            this.form.partner_doctor_id = this.selected_partner_doctor.id;
          }
        );
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
